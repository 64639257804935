<div class="center">
    <div class="fr-container--fluid">
        <div class="fr-grid-row fr-grid-row--gutters fr-grid-row--center">
            <div class=" fr-col-10 fr-col-sm-7 fr-col-md-8 fr-col-lg-7 fr-col-xl-6 ">
                <h1 attr.aria-label="{{'ACCUEIL.TITRE' | translate}}">{{'ACCUEIL.TITRE' | translate}}</h1>
                <h2 attr.aria-label="{{'ACCUEIL.SOUS_TITRE' | translate}}">{{'ACCUEIL.SOUS_TITRE' | translate}}</h2>
            </div>
        </div>

        <div class="fr-grid-row fr-grid-row--gutters">
            <!-- bloc tuile  -->
            <div class=" fr-col-10 fr-col-sm-10 fr-col-md-4 fr-col-lg-4 fr-col-xl-3 custom-margin-left-col">
                        <div class="fr-card cutom-height">
                            <div class="fr-card__body">
                                <div class="fr-card__content">
                                    <h3 class="fr-card__title" [innerHTML]="'ACCUEIL.TITRE_CARTE_INFORMATION' | translate ">
                                    </h3>
                                    <p class="fr-card__desc" [innerHTML]="'ACCUEIL.CONTENU_CARTE_INFORMATION' | translate "></p>
                                </div>
                            </div>
                </div>
            </div>

            <div class=" fr-col-10 fr-col-sm-12 fr-col-md-7 fr-col-lg-7 fr-col-xl-7 custom-margin-left-col" >
                <div class="fr-grid-row fr-grid-row--gutters">
                    <div class="fr-col-12">
                        <label class="champ_obligatoire" attr.aria-label="{{ 'ACCUEIL.CHAMPS_OBLIGATOIRES' | translate }}">{{ 'ACCUEIL.CHAMPS_OBLIGATOIRES' | translate }}</label>
                    </div>
                    <div class="fr-col-12 ">
                        <app-profil></app-profil>
                    </div>

                    <div class="fr-col-12">
                        <app-etablissement #etablissement></app-etablissement>
                    </div>
                    <div class="fr-col-12">
                        <app-commune></app-commune>
                    </div>
                    <div class="fr-col-12">
                        <app-autocomplete-etab></app-autocomplete-etab>
                    </div>
                    <div class="fr-col-12">
                        <app-connexion></app-connexion>
                    </div>
                </div>
            </div>
    </div>
</div>
