<main role="main">
    <div class="editorial_contenu">
      <app-retour-accueil></app-retour-accueil>
      <div class="container">
  
        <h2  id="first-contenu" tabindex="0"> <!-- titre de niveau 2 -->
            À propos du service de découverte du GAR 
        </h2> <!--  fin du titre de niveau 2 -->
        <p tabindex="0"> <!-- début de paragraphe -->
            Le service de découverte du GAR est proposé aux élèves et aux agents des établissements ou des écoles pour leur permettre de s’authentifier, avec des solutions sécurisées par l’éducation nationale, dans des parcours d’accès directs depuis leurs ressources via le GAR. 
        </p> <!-- fin de paragraphe -->
        <h2 tabindex="0"> <!-- titre de niveau 2 -->
            Mentions légales 
        </h2> <!--  fin du titre de niveau 2 -->
        <h5 tabindex="0"> <!--  Début du titre de niveau 3 -->
          Éditeur
        </h5> <!--  fin du titre de niveau 3 -->
        <p tabindex="0"> <!-- début de paragraphe -->
          <strong> <!--Balise de mise en page "gras" -->
            Ministère de l’Éducation nationale et de la Jeunesse - Direction du numérique pour l'Éducation (DNE)
          </strong> <!--Fin de la Balise de mise en page "gras" -->
          <span class="retour-chariot">
          110 rue de Grenelle 75007 Paris
        </span>
          <span class="retour-chariot">
          Téléphone : 01 55 55 10 10
        </span> <!--Balise de saut de ligne -->
          <span class="retour-chariot">
          FAX : +33 1 55 55 38 54
        </span>
        </p> <!-- fin de paragraphe -->
        <h5 tabindex="0"> <!--  Début du titre de niveau 3 -->
          Propriété intellectuelle
        </h5> <!--  fin du titre de niveau 3 -->
        <p tabindex="0"> <!-- début de paragraphe -->
          <span class="retour-chariot">
            La présentation et le contenu de la présente interface constituent une œuvre protégée par la législation française relative à la propriété intellectuelle, dont le Ministère en charge de l’éducation nationale est titulaire. 
        </span> <!--Balise de saut de ligne -->
          <span class="retour-chariot">
            Seules les reproductions et utilisations des logos et visuels présents sur la console d’affectation ou sur le portail GAR, aux fins exclusives d’information pédagogique, pourront être effectuées sans autorisation préalable. 
        </span> <!--Balise de saut de ligne -->
        </p>  <!-- fin de paragraphe -->
        <h5 tabindex="0"> <!--  Début du titre de niveau 5 -->
            Droits de reproduction des documents publics ou officiels
        </h5>  <!--  fin du titre de niveau 5 -->
        <p tabindex="0"> <!-- début de paragraphe -->
          <span class="retour-chariot">
            Depuis la publication de l'ordonnance n° 2005-650 du 6 juin 2005 relative à la liberté d'accès aux documents administratifs et à la réutilisation des informations publiques, ces informations peuvent être réutilisées à d'autres fins que celles pour lesquelles elles ont été produites, et particulièrement les informations faisant l'objet d'une diffusion publique. Le graphisme, l'iconographie ainsi que le contenu éditorial demeurent la propriété du Ministère et, à ce titre, font l'objet des protections prévues par le Code de la propriété intellectuelle. 
        </span>
          <span class="retour-chariot">
            Si la reprise de ces contenus de façon partielle ou intégrale est autorisée, elle doit être obligatoirement assortie de la mention du nom de l'auteur, de la source, et éventuellement d'un lien renvoyant vers le document original en ligne sur la présente interface. Tous les autres contenus présents sur cette interface sont couverts par le droit d'auteur. Toute reprise est dès lors conditionnée à l'accord de l'auteur en vertu de l'article L.122-4 du Code de la propriété Intellectuelle. Les informations utilisées ne doivent l'être qu'à des fins personnelles, associatives ou professionnelles ; toute diffusion ou utilisation à des fins commerciales ou publicitaires étant interdites. 
        </span>
          <span class="retour-chariot">
        Si la reprise de ces contenus de façon partielle ou intégrale est autorisée, elle doit être obligatoirement
        assortie de la mention du nom de l'auteur, de la source, et éventuellement d'un lien renvoyant vers le document
        original en ligne sur la présente interface. Tous les autres contenus présents sur cette interface sont couverts par le droit
        d'auteur. Toute reprise est dès lors conditionnée à l'accord de l'auteur en vertu de l'article L.122-4 du Code
        de la propriété Intellectuelle. Les informations utilisées ne doivent l'être qu'à des fins personnelles,
        associatives ou professionnelles ; toute diffusion ou utilisation à des fins commerciales ou publicitaires étant
        interdites.
        </span>
        </p>  <!-- fin de paragraphe -->
        <h5 tabindex="0"> <!--  Début du titre de niveau 5 -->
          Contenus externes à la présente interface
        </h5>  <!--  fin du titre de niveau 5 -->
        <p tabindex="0"> <!-- début de paragraphe -->
          <span class="retour-chariot">
            La propriété intellectuelle du Ministère et du GIP RENATER qui opère le GAR pour le compte du ministère, ne concerne pas les contenus externes aux services de découverte du GAR et accessibles via les liens hypertextes proposés pour l’accès aux différentes ressources numériques pour l’éducation. Ces contenus ne font pas partie intégrante du service de découverte du GAR. 
        </span>
          <span class="retour-chariot">
        En conséquence :
        </span>
        <ul>
          <li> <!--  balise liste -->
            RENATER et le Ministère ne sauraient être tenus pour responsables du contenu des sites auxquels l'internaute aurait ainsi accès.
          </li> <!--  fin de la balise liste -->
          <li> <!--  balise liste -->
            Ces liens externes ne sauraient engager la responsabilité de l’éditeur du médiacentre ÉduGAR, ni celle des directeurs de publication vis-à-vis d’un quelconque dysfonctionnement du lien ou une indisponibilité des contenus diffusés par ces sites liés.
          </li> <!--  fin de la balise liste -->
        </ul>
  
        <h3 tabindex="0"> <!-- titre de niveau 3 -->
          Données à caractère personnel
        </h3>  <!--  fin du titre de niveau 3 -->
        <p tabindex="0"> <!-- début de paragraphe -->
          <span class="retour-chariot">
          Le ministère de l'éducation nationale pour permettre l’accès des élèves, des enseignants et autres agents concernés
          à leurs ressources numériques et services associés, met en œuvre en tant que responsable le traitement de données
            à caractère personnel dénommé <q> Gestionnaire d’accès aux ressources </q> (GAR).
          </span>
          <span class="retour-chariot">
          Lien vers la page mentions d’information RGPD :
          <a href="https://gar.education.fr/mentions-informatives-rgpd/">
            GAR - Mentions informatives RGPD (education.fr)
          </a>
        </span>
        </p>  <!-- fin de paragraphe -->
        <h3 tabindex="0"> <!-- titre de niveau 3 -->
            Accès aux services de découverte du GAR 
        </h3> <!--  fin du titre de niveau 3 -->
        <p tabindex="0"> <!-- début de paragraphe -->
          <span class="retour-chariot">
            L'utilisateur d’un service de découverte du GAR va pouvoir s’authentifier via des systèmes sécurisés de l’éducation nationale pour accéder à une ressource à laquelle il a droit. L’utilisateur doit respecter les consignes indiquées pour cette authentification. 
        </span>
  
          <span class="retour-chariot">
            Il est rappelé que le fait d'accéder ou de se maintenir frauduleusement dans un système informatique, d'entraver ou de fausser le fonctionnement d'un tel système, d'introduire ou de modifier frauduleusement des données dans un système informatique constituent des délits passibles de sanctions pénales. 
        </span>
        </p>
     
        <h3 tabindex="0"> <!-- titre de niveau 3 -->
            Fonctionnement des services de découverte du GAR pour l’authentification des utilisateurs 
        </h3>
        <p tabindex="0"> <!-- début de paragraphe -->
            Pour toute demande ou sollicitation concernant le fonctionnement des services de découverte du GAR, vous pouvez adresser directement une demande au support d’assistance du GAR si vous avez les droits pour le faire, ou vous adresser aux responsables affectation de votre établissement ou école pour les enseignants, ou à votre support académique, et à votre enseignant pour les élèves.  
        </p>  <!-- fin de paragraphe -->
        <h3 tabindex="0"> <!-- titre de niveau 3 -->
          Clause attributive de compétence
        </h3> <!--  fin du titre de niveau 3 -->
        <p tabindex="0"> <!-- début de paragraphe -->
            Les services de découverte du GAR est soumis à la loi française. En cas de litige, les tribunaux français seront seuls compétents. 
        </p>  <!-- fin de paragraphe -->

    <br>
      </div>
    </div>
  </main>
  