import { Component, Input, OnInit } from '@angular/core';
import { Favoris } from 'src/app/model/favoris';
import { RedirectToAARService } from 'src/app/service/redirect-to-aar.service';
import { Etablissement } from "../../../model/etablissement";

@Component({
  selector: 'app-favoris-tuile',
  templateUrl: './tuileFavoris.component.html',
  styleUrls: ['./tuileFavoris.component.css']
})
export class TuileFavorisComponent implements OnInit {

  PATH_IMG_AGENT: string = "../../../../assets/img/agent.svg"
  PATH_IMG_ELEVE: string = "../../../../assets/img/eleve.svg"

  profil: string = "";
  etablissement: Etablissement;
  typeEtablissement: string = "";

  @Input()
  favoris!: Favoris

  constructor(private redirectService:RedirectToAARService) {
    this.etablissement = new Etablissement("",
    "","","","");
  }

  ngOnInit(): void {
    this.etablissement=this.favoris.etablissement
    this.typeEtablissement=this.favoris?.typeEtab
    this.profil=this.favoris?.profil
  }

  redirectToIdp(){
    this.redirectService.goToAAR(this.etablissement.idEtablissementUai,this.profil)
  }
}
