import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ListeEtablissements } from '../model/etablissement';
import { Pagination } from '../model/pagination';

@Injectable({
  providedIn: 'root'
})
export class EtablissementService {
  url:string="/api/etablissement"

  constructor(private http: HttpClient) { }

  public getListeEtablissement(nomEtab:string, codeInseeCommune:string, type:string,pagination:Pagination):Observable<ListeEtablissements> {
    let urlWs=this.builderUrl(nomEtab,codeInseeCommune,type,this.url)
    console.log(urlWs)
    let params = new HttpParams().set('page', pagination.page)
    console.log(params)
    return this.http
    .get<ListeEtablissements>(urlWs,{ params: params })
    .pipe(
      map(json => {
        return json
  }))
}

builderUrl(nomCommune:string, codeInseeCommune:string,type:string,urlWs:string):string{
  if(nomCommune ) {
    urlWs+="?nom="+nomCommune
  }
  if(codeInseeCommune){
    if(nomCommune){
      urlWs+="&codeInseeCommune="+codeInseeCommune
    } else {
      urlWs+="?codeInseeCommune="+codeInseeCommune
    }
  }
  if(type){
    if(!nomCommune && !codeInseeCommune){
      urlWs+="?type="+type
    }else {
      urlWs+="&type="+type
    }
  }
  return urlWs
}
}
