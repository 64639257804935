import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ListeCommunes } from '../model/commune';

@Injectable({
  providedIn: 'root'
})
export class CommuneService {
  url:string="/api/commune"

  constructor(private http: HttpClient) { }

  public getListeCommunes(nomCommune:any, codePostal:any):Observable<ListeCommunes> {
    let urlWs=this.url
    if(nomCommune ==null && codePostal !== null) {
      urlWs+="?codePostal="+codePostal
    }else if(nomCommune !==null){
      urlWs+="?nom="+nomCommune
    }
    console.log(urlWs)
    return this.http
    .get<ListeCommunes>(urlWs)
    .pipe(
      map(json => {
        console.log(json)
        return json
  }))
}
}
