import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Commune } from '../model/commune';
import { Etablissement } from '../model/etablissement';

@Injectable({
  providedIn: 'root'
})
export class SharingdataService {

  construct:string[]=[]

  public selectedCommune = new BehaviorSubject(new Commune("","",""))
  public sharedCommune = this.selectedCommune.asObservable();

  public selectedTypeEtab= new BehaviorSubject("")
  public sharedTypeEtab = this.selectedTypeEtab.asObservable();

  public selectedProfil= new BehaviorSubject("")
  public sharedProfil = this.selectedProfil.asObservable();

  public selectedEtab= new BehaviorSubject(new Etablissement("","","","",""))
  public sharedEtab = this.selectedEtab.asObservable();

  public valueStored= new BehaviorSubject(this.construct)
  public dataLocalStorage = this.valueStored.asObservable();

  public resetData= new BehaviorSubject("")
  public sharedReset = this.resetData.asObservable();

  constructor() { }
  
  changeCommune(commune: Commune) {
    this.selectedCommune.next(commune)
  }
  changeTypeEtab(typeEtab:string) {
    this.selectedTypeEtab.next(typeEtab)
  }
  changeProfil(profil:string){
    this.selectedProfil.next(profil)
  }

  changeEtab(etab:Etablissement){
    this.selectedEtab.next(etab)
  }

  changeValueStorage(elementLocaleStorage:string[]){
    this.valueStored.next(elementLocaleStorage)
  }

  reset(value:string){
    this.changeCommune(new Commune("","",""))
    this.changeEtab(new Etablissement("","","","",""))
    this.changeProfil("")
    this.changeTypeEtab("")
  }
}
