export interface ListeEtablissements {
    listeEtablissements: Etablissement[];
    totalElements:number
  }

  export class Etablissement {
    idEtablissementUai: string=""
    nomEtablissement: string=""
    codeInseeCommune:string=""
    nomCommune:string=""
    codePostal:string=""
    constructor(
      idEtablissementUai: string,
      nomEtablissement: string,
      nomCommune:string,
      codeInseeCommune:string,
      codePostal:string){

      //Alimentation des propriétés pour TEST lors de l'affichage des favoris
      this.idEtablissementUai = idEtablissementUai;
      this.nomEtablissement = nomEtablissement;
      this.nomCommune = nomCommune;
      this.codeInseeCommune = codeInseeCommune;
      this.codePostal = codePostal;
    }

  }
