import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestion-cookies',
  templateUrl: './gestion-cookies.component.html',
  styleUrls: ['./gestion-cookies.component.css']
})
export class GestionCookiesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
