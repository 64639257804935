import { Component,  OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map, Observable, startWith, Subject, take } from 'rxjs';
import { Etablissement, ListeEtablissements } from 'src/app/model/etablissement';
import { Pagination } from 'src/app/model/pagination';
import { EtablissementService } from 'src/app/service/etablissement.service';
import { SharingdataService } from 'src/app/service/sharingdata.service';

@Component({
  selector: 'app-autocomplete-etab',
  templateUrl: './autocomplete-etab.component.html',
  styleUrls: ['./autocomplete-etab.component.css']
})
export class AutocompleteEtabComponent implements OnInit {

    myControl = new FormControl('');
    options: Set<string>=new Set()
    resultSize=0
    selectedEtab!:Etablissement
    resultWS: Map<string,Etablissement>=new Map()
    isLoading=false
    errorOption=""
    paginationParam!:Pagination
    filtredResult:string[]=[]
    totalElements:number=0
    countElementByPagination=0

    @ViewChild("auto")
    autocomplete!: MatAutocomplete;
    showResultSize:Boolean=false
    filteredOptions!: Observable<string[]>;
    inputAuto=new Subject<string>()


    constructor(private etablissementService:EtablissementService,
      private sharingdataService:SharingdataService,
      private translate: TranslateService) {
        this.paginationParam= new Pagination(20,0);
        //this.scrollDispatcher.scrolled().subscribe(x => console.log('I am scrolling'));
        this.inputAuto.pipe(
          debounceTime(400),
          distinctUntilChanged())
          .subscribe(value => {
            this.logEvent(value);
          });
     }


     ngOnInit() {
       //aria-required ne marche pas sur le composant auto-complete
       this.myControl=new FormControl('', Validators.required)
       //reset l'autocomplete si l'utilisateur reset input commune
       this.sharingdataService.sharedEtab.subscribe(el=> {
         if(!(el?.nomEtablissement && el?.nomCommune && el?.idEtablissementUai && el?.codePostal && el?.codeInseeCommune)) {
           this.reset()
         }
       })
       this.renderContentAutoComplete()
     }

     private _filter(value: string): string[] {
       const filterValue = this.myControl.value?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") || "";
       this.filtredResult=[]
       this.filtredResult=[...this.options].filter(option => option.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase().includes(filterValue))
       if (this.filtredResult.length == 0) {
        if ((this.myControl.value?.length || 0) > 2) {
          this.translate.get("ACCUEIL.ETABLISSEMENT_NON_TROUVE").subscribe(result => this.errorOption = result)
          this.resultSize=0
        }
      }
      else {
        this.errorOption = ""
        this.resultSize=this.totalElements
      }
       return this.filtredResult;
     }

   public  checkIsNumber(param:string): boolean {

    let regex=/^[0-9]+$/;
    if(param?.match(regex)) return true
    return false;
   }
   public logEvent(val:string) {
     // pour éviter le control des erreurs sur le formControl (required)
     this.myControl.setErrors(null)
     this.paginationParam.page = 0
     if (val?.length < 3) {
      this.possiblityToClear()
      return
     }
     if (val?.length >= 3)
       this.callWsEtablissement(val);
     

  }

  callWsEtablissement(val:string){
    this.isLoading = true
    this.sharingdataService.sharedCommune.pipe(take(1)).subscribe(commune => {
      this.sharingdataService.sharedTypeEtab.pipe(take(1)).subscribe(type => {
        this.etablissementService.getListeEtablissement(val, commune.codeInseeCommune, type,this.paginationParam).pipe(take(1)).subscribe((res: ListeEtablissements) => {
          if(this.paginationParam.page ==0 ){
           this.options.clear()
           this.resultWS.clear()
           this.countElementByPagination=0
          }
          this.totalElements=res.totalElements
          this.resultSize=res.totalElements
          this.showResultSize = true
          //permet d'attendre le chargement complet de la liste
          setTimeout(()=> {
            res.listeEtablissements.forEach(el => {
              this.options.add(el?.nomEtablissement +' ('+el?.nomCommune+')')
              this.resultWS.set(el?.nomEtablissement +' ('+el?.nomCommune+')', el)
              this.countElementByPagination++
  
            })
            this.renderContentAutoComplete()
            this.possiblityToClear()
          }) 
          
          this.isLoading = false
        }, (err) => {
          this.resultSize = 0
          this.isLoading = false
        })
      })
    })
  }
    public renderContentAutoComplete() {
      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
      );
    }

    public reset(){
      this.myControl.setValue("")
      this.options.clear()
      this.renderContentAutoComplete()
      this.selectedEtab={"nomEtablissement":"","idEtablissementUai":"","nomCommune": "","codeInseeCommune":"","codePostal":""}
      this.showResultSize=false
      this.resultSize=0
      this.paginationParam.page=0
    }
    selectEtab(option:string){
      this.selectedEtab=this.resultWS.get(option) || {"nomEtablissement":"","idEtablissementUai":"","nomCommune": "","codeInseeCommune":"","codePostal":""}
      this.sharingdataService.changeEtab(this.selectedEtab)
      this.showResultSize=false
      this.myControl.setValue(option)
      this.paginationParam.page=0
  }
  getPremiersCaractereDeString(caracteristique:string, nombreCaractere: number):string{
    return (caracteristique.slice(0,nombreCaractere)) + (caracteristique.length > nombreCaractere?'...':'');
  }


  public onAutocompleteScroll(): void {

    this.autocomplete?.panel?.nativeElement?.addEventListener(
      "scroll",
      (event: any) => {
        // condition pour ne pas faire d'appel au ws si on arrive à la fin de la liste en scroll
        if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 1 && (this.totalElements>this.countElementByPagination)) {
          console.log("End");
          this.paginationParam.page++;
          let val: string = this.myControl?.value || ""
          if(val.length>=3)
          this.callWsEtablissement(val)
        }
      }
    );


  }
  possiblityToClear(){
    
    if((this.myControl?.value?.length || 0) < 3) {
      this.isLoading=false
      this.options.clear
      this.resultWS.clear
      this.filteredOptions = new Observable<string[]>()
      this.errorOption = ""
      this.paginationParam.page = 0
      this.showResultSize = false
    }
  }

  }
