import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  lessThan_571 = false;
  constructor(private _router:Router) { }

  ngOnInit(): void {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.lessThan_571 = window.innerWidth < 571;
  }
  goToAide(){
    this._router.navigate(['/aide'])
  }

  sendFocusToProfil(){
    if(this._router.url.toString() == "/accueil") {
      console.log(document?.getElementById("Agent"))
      document?.getElementById("Agent")?.focus()
    } else if (this._router.url.toString() == "/favoris") {
      console.log(document?.getElementById("labelEtab"));
      document?.getElementById("labelEtab")?.focus();
    }
  }
}
