import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'wayf-native-ihm';

  public constructor(private translate:TranslateService) {
    this.translate.setDefaultLang('fr');
  }
}
