import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  scrWidth!: number;
  lessThan_576!: boolean;
  constructor() { }

  ngOnInit(): void {
    this.getScreenSize()
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.scrWidth = window.innerWidth;
    // this.showNav = this.scrWidth >= 992 ? true : false
    this.lessThan_576 = this.scrWidth < 576 ? true : false;
  }
}
