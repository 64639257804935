<div class="fr-card fr-enlarge-link fr-card--horizontal fr-card--horizontal-tier" style="cursor: pointer;" id="{{type}}" tabindex="0">
    <div class="fr-card__body" [ngClass]="{'background_body': isTileSelected }">
            <label class="fr-card__title" attr.aria-label="{{type}}" [ngClass]="{'background_body': isTileSelected }">
                {{type}}
            </label>
    </div>
    <div class="fr-card__header"  [ngClass]="{'color_background': isTileSelected }">
        <div style="background-color: white;">
            <img *ngIf='isTileSelected' src="../../../assets/img/validate.svg" class="icon-custom" attr.aria-label="{{logo}}" aria-hidden="true">
        </div>
        <div  class="fr-card__img">
            <img class="fr-responsive-img" src="../../../assets/img/{{logo}}" alt="" />
            <!-- <span class="fr-icon-user-fill" aria-hidden="true"></span> -->
            <!-- L’alternative de l’image (attribut alt) doit toujours être présente, sa valeur peut-être vide (image n’apportant pas de sens supplémentaire au contexte) ou non (porteuse de texte ou apportant du sens) selon votre contexte -->
        </div>
    </div>
</div>
