export interface ListeCommunes {
    listeCommunes: Commune[];
  }

  export class Commune {
    nomCommune:string="";
    codeInseeCommune:string="";      
    codePostal:string=""
    constructor(
      nomCommune:string,
      codeInseeCommune:string,      
      codePostal:string){}

  }