import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SharingdataService } from 'src/app/service/sharingdata.service';

@Injectable({
  providedIn: 'root'
})
export class AccessFavorisGuard implements CanActivate {

elementLocaleStorage: string[] = [];

constructor(private readonly sharingDataService: SharingdataService,
            private readonly router: Router) {}

  canActivate(): boolean {
    let hasAccess = false;
    Object.keys(localStorage).forEach(el=>{

      let dateList=el.split('_')
      let value=localStorage.getItem(el) as string

      if(dateList && dateList.length==2 && dateList[0]=='wayfNative' ){
        if(!this.elementLocaleStorage.includes(value)){
          this.elementLocaleStorage.push(value)
        } 
        hasAccess = true;
      }
    })
    this.sharingDataService.changeValueStorage(this.elementLocaleStorage);

    if(!hasAccess) {
      this.router.navigate(["/acceuil"]);
    }
    return hasAccess; 
  }
}