import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { Etablissement } from 'src/app/model/etablissement';
import { SharingdataService } from 'src/app/service/sharingdata.service';

@Component({
  selector: 'app-etablissement',
  templateUrl: './etablissement.component.html',
  styleUrls: ['./etablissement.component.css']
})
export class EtablissementComponent implements OnInit {

  public lessThan_768!:boolean

  selecteEcole=false
  selecteCollege=false
  selecteLycee=false
  selecteAutre=false
  typeSelected:string=''
  constructor(private sharingDataService:SharingdataService) { }

  ngOnInit(): void {
    this.getScreenSize()
  }

  clickTile(param:string) {
    switch(param) {
      case 'Ecole' : {
        this.selecteEcole=!this.selecteEcole
        this.selecteCollege=false
        this.selecteLycee=false
        this.selecteAutre=false
        this.selecteEcole ? this.sharingDataService.changeTypeEtab('ECOLE'):this.sharingDataService.changeTypeEtab('')
        this.sharingDataService.changeEtab(new Etablissement("","","","",""))
        break
      }
      case 'College': {
        this.selecteEcole=false
        this.selecteCollege=!this.selecteCollege
        this.selecteLycee=false
        this.selecteAutre=false
        this.selecteCollege? this.sharingDataService.changeTypeEtab('COLLEGE'):this.sharingDataService.changeTypeEtab('')
        this.sharingDataService.changeEtab(new Etablissement("","","","",""))
        break
      }
      case 'Lycee' : {
        this.selecteEcole=false
        this.selecteCollege=false
        this.selecteAutre=false
        this.selecteLycee=!this.selecteLycee
        this.selecteLycee ? this.sharingDataService.changeTypeEtab('LYCEE'): this.sharingDataService.changeTypeEtab('')
        this.sharingDataService.changeEtab(new Etablissement("","","","",""))
        break
      }
      case 'Autre': {
        this.selecteEcole=false
        this.selecteCollege=false
        this.selecteLycee=false
        this.selecteAutre=!this.selecteAutre
        this.selecteAutre ? this.sharingDataService.changeTypeEtab('AUTRE') :this.sharingDataService.changeTypeEtab('')
        this.sharingDataService.changeEtab(new Etablissement("","","","",""))
        break
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.lessThan_768 = window.innerWidth < 768;
  }
}
