<footer class="fr-footer" role="contentinfo" id="footer">
    <div class="fr-container" style="max-width: 100%;">
        <div class="fr-footer__bottom">
            <ul class="fr-footer__bottom-list" >
                <li class="fr-footer__bottom-item">
                    <a class="fr-footer__bottom-link" attr.aria-label="{{'FOOTER.DECLARATION_CONFORMITE' | translate}}" href="/conformite" target="_blank">{{'FOOTER.DECLARATION_CONFORMITE' | translate}}</a>
                </li>
                <li class="fr-footer__bottom-item">
                    <a class="fr-footer__bottom-link" attr.aria-label="{{'FOOTER.MENTIONS_LEGALES' | translate}}"  routerLink="page/mentionslegales">{{'FOOTER.MENTIONS_LEGALES' | translate}}</a>
                </li>
                <li class="fr-footer__bottom-item">
                    <a class="fr-footer__bottom-link" attr.aria-label="{{'FOOTER.DONNEES_PERSONNELLES' | translate}}" href="/rgpd" target="_blank">{{'FOOTER.DONNEES_PERSONNELLES' | translate}}</a>
                </li>
                <li class="fr-footer__bottom-item">
                    <a class="fr-footer__bottom-link" attr.aria-label="{{'FOOTER.GESTION_COOKIE' | translate}}" routerLink="page/gestiondescookies">{{'FOOTER.GESTION_COOKIE' | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
</footer>
