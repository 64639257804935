import {of as observableOf, Observable} from 'rxjs';
import {TranslateLoader} from "@ngx-translate/core";

export class CustomLoader implements TranslateLoader {

    getTranslation(lang: string): Observable<any> {
        return observableOf(
            {
                "ACCUEIL": {
                    "TITRE":"Connexion à la ressource",
                    "SOUS_TITRE":"Je dois renseigner les informations demandées pour me connecter à la ressource",
                    "CHAMPS_OBLIGATOIRES":"* champs obligatoires",
                    "SELECTIONNE_PROFIL": "Je sélectionne mon profil *",
                    "SELECTIONNE_ETABLISSEMENT": "Je sélectionne l'école ou le type d’établissement",
                    "SAISIE_LIEU_ETAB": "Je renseigne le lieu de mon école ou établissement",
                    "SAISIE_NOM_CODE_POSTAL": "Rechercher le code postal ou le nom de la commune en commençant par les premiers caractères",
                    "RESULTAT_WEB_SERVICE_COMMUNE":"Résultat ",
                    "RENSEIGNE_MON_ETABLISSEMENT":"Je choisis mon école ou établissement",
                    "LABEL_ETABLISSEMENT_OBLIGATOIRE":"Rechercher une école ou un établissement en commençant par les premiers caractères *",
                    "LABEL_CHECKBOX":"J'enregistre mon choix pour la prochaine fois ",
                    "BUTTON_SE_CONNECTER":"Je me connecte",
                    "BUTTON_DERNIERE_CONNEXION":"Mes dernières connexions",
                    "COMMUNE_NON_TROUVEE":"Aucune commune trouvée",
                    "CODE_POSTAL_INCORRECT":"Le code postal est incorrect",
                    "ETABLISSEMENT_NON_TROUVE":"Aucun établissement trouvé",
                    "TITRE_CARTE_INFORMATION":"Vous pouvez&nbsp;:&nbsp;",
                    "CONTENU_CARTE_INFORMATION":"<ul _ngcontent-adc-c60=\"\" ><li>utiliser votre compte élève ou agent pour vous authentifier, en renseignant les informations demandées sur votre ENT ou votre guichet&nbsp;;</li>"+
                    "<li>enregistrer ces informations pour vos prochaines connexions&nbsp;;</li><li>utiliser vos ressources pédagogiques en toute sécurité, via un navigateur internet ou une application locale, avec vos données personnelles protégées.</li></ul> "
                },
                "ACTIONS": {
                    "RETOUR":"< Retour à la page d'accueil"
                },
                "FAVORIS" : {
                    "TITRE" : "Connexion à la ressource",
                    "CHOIX_DERNIERE_CONNEXIONS": "Choisissez parmi vos dernières connexions",
                    "DERNIERE_CONNEXIONS": "Mes dernières connexions",
                    "FAIRE_UN_CHOIX": "Je choisis une autre école ou établissement",
                    "SE_CONNECTER": "Je me connecte",
                    "TITRE_CARTE_INFORMATION" :"Vous pouvez : ",
                    "CONTENU_CARTE_INFORMATION":"<ul _ngcontent-adc-c60=\"\" ><li>utiliser votre compte élève ou agent pour vous authentifier, en renseignant les informations demandées sur votre ENT ou votre guichet&nbsp;;</li>"+
                    "<li>enregistrer ces informations pour vos prochaines connexions&nbsp;;</li><li>utiliser vos ressources pédagogiques en toute sécurité, via un navigateur internet ou une application locale, avec vos données personnelles protégées.</li></ul> "

                },
                "HEADER":{
                    "BUTTON_AIDE":"Aide",
                    "BUTTON_FERMER":"Fermer"
                },
                "FOOTER": {
                    "MENTIONS_LEGALES": "Mentions légales",
                    "DECLARATION_CONFORMITE": "Accessibilité : non conforme",
                    "GESTION_COOKIE": "Gestion des cookies",
                    "DONNEES_PERSONNELLES": "Données personnelles"
                }

            }

        );
    }
}
