import { Component, HostListener, OnInit } from '@angular/core';
import { SharingdataService } from 'src/app/service/sharingdata.service';
import { Favoris } from 'src/app/model/favoris';
import { Router } from '@angular/router';

@Component({
  selector: 'app-favoris',
  templateUrl: './favoris.component.html',
  styleUrls: ['./favoris.component.css']
})
export class FavorisComponent implements OnInit {

  listeFavoris:Favoris[]=[]

  constructor(private readonly sharingdataService: SharingdataService, private router:Router) {
  }

  ngOnInit(): void {
    this.sharingdataService.dataLocalStorage.subscribe((res :string[]) => {
     res.forEach(element => {
        this.listeFavoris.push(JSON.parse(element as string))
     });
    });
  }

  redirectToAccueil(){
    this.router.navigate(['accueil'])
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(): boolean {
    return window.innerWidth <= 575 ? true : false;
  }
}
