
<main role="main">
    <div _ngcontent-adc-c60="" class="editorial_contenu declaration">
        <app-retour-accueil></app-retour-accueil>
      <h3 _ngcontent-adc-c60="" id="h5o-2">Gestion des cookies </h3>
      <p _ngcontent-adc-c60="">
        Le service de découverte du GAR est proposé par le Ministère en charge de l’éducation nationale, dans le cadre du service public du numérique, aux élèves et aux agents des établissements scolaires concernés et est exempté de consentement. Les seuls cookies collectés sont ceux qui sont nécessaires au fonctionnement technique du service de découverte.       </p>
      <br _ngcontent-adc-c60="">
     
    </div>
  </main>
  
  
