<div class="fr-grid-row fr-grid-row--gutters">
    <div class="fr-col-12">
        <label attr.aria-label="{{ 'ACCUEIL.SELECTIONNE_ETABLISSEMENT' | translate }}" class="champ_title">{{ 'ACCUEIL.SELECTIONNE_ETABLISSEMENT' | translate }}</label>
    </div>
</div>
<div class="fr-grid-row fr-grid-row--gutters">
    <div class="fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4">
        <app-tuile [type]="'Ecole'" [logo]="'ecole.svg'" (keyup.enter)="clickTile('Ecole')" (click)="clickTile('Ecole')" [isTileSelected]="selecteEcole" ></app-tuile>
    </div>
    <div class="fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4">
        <app-tuile  [type]="'Collège'" [logo]="'college.svg'" (keyup.enter)="clickTile('College')" (click)="clickTile('College')" [isTileSelected]="selecteCollege"></app-tuile>
    </div>
    <div class="fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4">
          <app-tuile  [type]="'Lycée'" [logo]="'lycee.svg'" (keyup.enter)="clickTile('Lycee')" (click)="clickTile('Lycee')" [isTileSelected]="selecteLycee" ></app-tuile>
    </div>
    <div class="fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4">
        <app-tuile  [type]="'Autre'" [logo]="'autre.svg'" (keyup.enter)="clickTile('Autre')" (click)="clickTile('Autre')" [isTileSelected]="selecteAutre"></app-tuile>
    </div>
</div>
