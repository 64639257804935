import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable, startWith, take } from 'rxjs';
import { Commune, ListeCommunes } from 'src/app/model/commune';
import { Etablissement } from 'src/app/model/etablissement';
import { CommuneService } from 'src/app/service/commune.service';
import { SharingdataService } from 'src/app/service/sharingdata.service';

@Component({
  selector: 'app-commune',
  templateUrl: './commune.component.html',
  styleUrls: ['./commune.component.css']
})
export class CommuneComponent implements OnInit {

  myControl = new FormControl('');
  options: Set<string> = new Set()
  resultWS: Map<string, Commune> = new Map()
  resultSize = 0
  newCommune = new Commune("", "", "")
  errorOption = ""
  isPaste=false;

  filteredOptions!: Observable<string[]>;

  selectedCommune!: Commune;
  showResultSize: Boolean = false
  isLoading = false


  constructor(private communeService: CommuneService,
    private sharingdataService: SharingdataService,
    private translate: TranslateService) {

  }


  ngOnInit() {
    this.renderContentAutoComplete()
  }

  private _filter(value: string): string[] {
    const filterValue = this.myControl.value?.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "") || ""
    let filtredResult = [...this.options].filter(option => option.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filterValue))
    this.resultSize=filtredResult?.length
    if (filtredResult.length == 0) {
      if (this.checkIsNumber(this.myControl.value || '') && (this.myControl.value?.length || 0) > 1) {
        this.translate.get("ACCUEIL.CODE_POSTAL_INCORRECT").subscribe(result => this.errorOption = result)
        this.resultSize=0
      }
      else if (!this.checkIsNumber(this.myControl.value || '0') && (this.myControl.value?.length || 0) > 2) {
        this.translate.get("ACCUEIL.COMMUNE_NON_TROUVEE").subscribe(result => this.errorOption = result)
        this.resultSize=0
      }
    }
    else {
      this.errorOption = ""
      this.resultSize=filtredResult?.length
    }
    return filtredResult;
  }

  public checkIsNumber(param: string): boolean {

    let regex = /^[0-9]+$/;
    if (param?.match(regex)) return true
    return false;
  }
  public logEvent() {
    let val: string = this.myControl.value  || ""
    if((!this.checkIsNumber(val) && val?.length < 3) || (this.checkIsNumber(val) && val?.length < 2)){
      this.errorOption=""
      this.showResultSize=false
      this.clearResult()
    }
    if (!this.checkIsNumber(val) && val?.length == 3 ) {
      this.callWSCommuneByNom(val);
    } else if (this.checkIsNumber(val) && (val?.length == 2 )) {
      this.callWSCommuneByCodePostal(val);
      
    }
    if (!this.checkIsNumber(val) && val?.length >= 3 && this.isPaste) {
      this.callWSCommuneByNom(val);
      this.isPaste=false;
    }
    else if (this.checkIsNumber(val) && (val?.length >= 2 && this.isPaste )) {
      this.callWSCommuneByCodePostal(val);
      this.isPaste=false;
    }
  }
  public callWSCommuneByNom(value:string){
    this.isLoading = true
    this.communeService.getListeCommunes(value, null).subscribe((res: ListeCommunes) => {
      this.options.clear()
      this.resultWS.clear()
      setTimeout(()=> {
        res.listeCommunes.forEach(el => {
        this.options.add(el?.nomCommune + ' (' + el?.codePostal + ')')
        this.resultWS.set(el?.nomCommune + ' (' + el?.codePostal + ')', el)
      })

      this.renderContentAutoComplete()
      this.clearResult()
    })
      this.showResultSize = true
      this.isLoading = false
    }, (err) => {
      this.resultSize = 0
      this.isLoading = false
    })
  }
  public callWSCommuneByCodePostal(value:string){
    this.isLoading = true
    this.communeService.getListeCommunes(null, value).subscribe((res: ListeCommunes) => {
      this.options.clear()
      this.resultWS.clear()
      setTimeout(()=> {
        res.listeCommunes.forEach(el => {
          this.options.add(el?.nomCommune + ' (' + el?.codePostal + ')')
          this.resultWS.set(el?.nomCommune + ' (' + el?.codePostal + ')', el)
        })
        this.renderContentAutoComplete()
        this.clearResult()
      })
      this.showResultSize = true
      this.isLoading = false
    }, (err) => {
      this.showResultSize = true
      this.resultSize = 0
      this.isLoading = false
    })
  }
  public renderContentAutoComplete() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }
  public reset() {
    let newCommune = new Commune("", "", "")
    this.myControl.setValue("")
    this.options.clear()
    this.renderContentAutoComplete()
    this.selectedCommune = newCommune
    this.sharingdataService.changeCommune(newCommune)
    this.sharingdataService.changeEtab(new Etablissement("", "", "", "", ""))
    this.showResultSize = false
  }
  public resetControl() {
    let newCommune = new Commune("", "", "")
    this.myControl.setValue("")
    this.options.clear()
    this.selectedCommune = newCommune
    this.sharingdataService.changeCommune(newCommune)
    this.sharingdataService.changeEtab(new Etablissement("", "", "", "", ""))
    this.showResultSize = false
  }
  selectCommune(option: string) {
    this.selectedCommune = this.resultWS.get(option) || new Commune("", "", "")
    this.sharingdataService.changeCommune(this.selectedCommune)
    this.sharingdataService.changeEtab(new Etablissement("", "", "", "", ""))
    this.showResultSize = false
    this.myControl.setValue(option)
  }
  getPremiersCaractereDeString(caracteristique: string, nombreCaractere: number): string {
    return (caracteristique.slice(0, nombreCaractere)) + (caracteristique.length > nombreCaractere ? '...' : '');
  }
  onPaste(){
    this.isPaste=true
  }
  clearResult() {
    let val = this.myControl.value || ""
    if ((!this.checkIsNumber(val) && val?.length < 3) || (this.checkIsNumber(val) && val?.length < 2)) {
      this.options.clear()
      this.resultWS.clear()
      this.errorOption = ""
      this.filteredOptions = new Observable<string[]>()
      this.showResultSize = false
    }
}
}
