import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app-rounting.module';
import { AccueilComponent } from './components/accueil/accueil.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CustomLoader } from './components/traduction/custom.i18n';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TuileComponent } from './components/tuile/tuile.component';
import { ProfilComponent } from './components/profil/profil.component';
import { EtablissementComponent } from './components/etablissement/etablissement.component';
import { CommuneComponent } from './components/commune/commune.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AutocompleteEtabComponent } from './components/autocomplete-etab/autocomplete-etab.component';
import { ConnexionComponent } from './components/connexion/connexion.component';
import { FavorisComponent } from "./components/favoris/favoris.component";
import { TuileFavorisComponent } from "./components/favoris/tuile/tuileFavoris.component";
import { AccessibiliteComponent } from './components/footer/accessibilite/accessibilite.component';
import { MentionsLegalesComponent } from './components/footer/mentions-legales/mentions-legales.component';
import { DonneesPersonnellesComponent } from './components/footer/donnees-personnelles/donnees-personnelles.component';
import { GestionCookiesComponent } from './components/footer/gestion-cookies/gestion-cookies.component';
import { RetourAccueilComponent } from './components/retour-accueil/retour-accueil.component';
import { AideComponent } from './components/header/aide/aide.component';
import { ScrollingDirective } from './scrolling.directive';

@NgModule({
  declarations: [
    AppComponent,
    AccueilComponent,
    HeaderComponent,
    FooterComponent,
    TuileComponent,
    ProfilComponent,
    EtablissementComponent,
    CommuneComponent,
    AutocompleteEtabComponent,
    ConnexionComponent,
    FavorisComponent,
    TuileFavorisComponent,
    AccessibiliteComponent,
    MentionsLegalesComponent,
    DonneesPersonnellesComponent,
    GestionCookiesComponent,
    RetourAccueilComponent,
    AideComponent,
    ScrollingDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ScrollingModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient]}

  }),
    MatNativeDateModule,
    MaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
 export function createTranslateLoader(http: HttpClient) :TranslateLoader {
  return new CustomLoader();
}
