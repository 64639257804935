import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RedirectToAARService {
  mapProfil=new Map()

  url="/AAR/wayfnatives"
  constructor() { 
    this.initProfilMap()
  }

  initProfilMap(){
    this.mapProfil.set('AGENT','AGT')
    this.mapProfil.set('ELEVE','ELV')
  }
  public goToAAR(idEtab:string, profil:string){
    window.location.href= this.url+"?idEtab="+window.btoa(idEtab)+"&profil="+this.mapProfil.get(profil)
    
  }

}