import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-donnees-personnelles',
  templateUrl: './donnees-personnelles.component.html',
  styleUrls: ['./donnees-personnelles.component.css']
})
export class DonneesPersonnellesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
