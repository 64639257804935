<div class="fr-grid-row fr-grid-row--gutters">
    <div class="fr-col-12">
        <div class="fr-checkbox-group">
            <input type="checkbox" attr.aria-label="{{ 'ACCUEIL.LABEL_CHECKBOX' | translate }}" (change)="checkInput()" id="checkbox" name="checkbox">
            <label class="fr-label" for="checkbox">{{ 'ACCUEIL.LABEL_CHECKBOX' | translate }}</label>
        </div>
    </div>
    <div class="fr-col-12"></div>
    <div class="fr-col-12 fr-col-sm-7 fr-col-md-5 fr-col-lg-4 fr-col-xl-3 "  [ngClass]="{'fr-col-offset-xl-2 fr-col-offset-lg-2 fr-col-offset-md-3': !showLastConnetion}">
        <button attr.aria-label="{{ 'ACCUEIL.BUTTON_SE_CONNECTER' | translate }}" [disabled]="!(isEtablissementSelected && isProfilSelected)" (click)="delegateAuthent()" class="fr-btn fr-btn--icon-left fr-icon-check-line">
            {{ 'ACCUEIL.BUTTON_SE_CONNECTER' | translate }}
        </button>
    </div>
    <div class="fr-col-12 fr-col-sm-7 fr-col-md-5 fr-col-lg-4 fr-col-xl-3" *ngIf="showLastConnetion">    
        <button (click)="loadFavoris()" attr.aria-label="{{ 'ACCUEIL.BUTTON_DERNIERE_CONNEXION' | translate }}" class="fr-btn fr-btn--secondary fr-btn--icon-left fr-icon-arrow-left-s-line">
            {{ 'ACCUEIL.BUTTON_DERNIERE_CONNEXION' | translate }}
        </button>
    </div>
</div>

