<div class="fr-grid-row fr-grid-row--gutters">
    <div class="fr-col-12">
        <label class="champ_title" attr.aria-label="{{ 'ACCUEIL.SELECTIONNE_PROFIL' | translate }}">{{ 'ACCUEIL.SELECTIONNE_PROFIL' | translate }}</label>
    </div>
</div>
<div class="fr-grid-row fr-grid-row--gutters">
    <div class="
    fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4">
        <app-tuile *ngIf="!lessThan_768" [type]="'Agent'" [logo]="'agent.svg'" (keyup.enter)="clickTileAgent()" (click)="clickTileAgent()" [isTileSelected]="selecteAgent" ></app-tuile>
        <app-tuile *ngIf="lessThan_768" [type]="'Agent'" [logo]="'agent-min.svg'" (keyup.enter)="clickTileAgent()" (click)="clickTileAgent()" [isTileSelected]="selecteAgent" ></app-tuile>
    </div>
    <div class="fr-col-12 fr-col-sm-10 fr-col-md-5 fr-col-lg-4 fr-col-xl-4">
        <app-tuile *ngIf="!lessThan_768" [type]="'Elève'" [logo]="'eleve.svg'" (keyup.enter)="clickTileEleve()" (click)="clickTileEleve()" [isTileSelected]="selecteEleve"></app-tuile>
        <app-tuile *ngIf="lessThan_768" [type]="'Elève'" [logo]="'eleve-min.svg'" (keyup.enter)="clickTileEleve()" (click)="clickTileEleve()" [isTileSelected]="selecteEleve"></app-tuile>
    </div>
</div>
