<div class="center">
  <div class="fr-container--fluid">
    <div class="fr-grid-row fr-grid-row--gutters fr-grid-row--center">
      <div class=" fr-col-10 fr-col-sm-7 fr-col-md-8 fr-col-lg-7 fr-col-xl-6 ">
        <h1>{{'FAVORIS.TITRE' | translate}}</h1>
        <h2>{{'FAVORIS.CHOIX_DERNIERE_CONNEXIONS' | translate}}</h2>
        <h3 class="derniereConnexion">{{'FAVORIS.DERNIERE_CONNEXIONS' | translate}}</h3>
      </div>
    </div>

    <div class="fr-grid-row fr-grid-row--gutters custom-center">
      <!-- bloc tuile  -->
      <div class=" fr-col-10 fr-col-sm-10 fr-col-md-4 fr-col-lg-3 fr-col-xl-2 custom-margin-left-col">
        <div class="fr-card  cutom-height custom-width">
          <div class="fr-card__body">
            <div class="fr-card__content">
              <h3 class="fr-card__title">
                {{'FAVORIS.TITRE_CARTE_INFORMATION' | translate}} 
              </h3>
              <p class="fr-card__desc"  [innerHTML]="'FAVORIS.CONTENU_CARTE_INFORMATION' | translate"> </p>
            </div>
          </div>
        </div>
      </div>

      <div class="fr-col-10 fr-col-sm-10 fr-col-md-7 fr-col-lg-8 fr-col-xl-8">
        <div class="custom-margin-left" [ngClass]="getScreenSize() ? 'fr-grid-row fr-grid-row--gutters fr-grid-row--center' : 'fr-grid-row fr-grid-row--gutters'">
          <div *ngFor="let favoris of listeFavoris" class="fr-col-12 fr-col-sm-5 fr-col-md-6 fr-col-lg-4 fr-col-xl-4">
            <app-favoris-tuile [favoris]="favoris" ></app-favoris-tuile>
          </div>
        </div>
        <div class="fr-col-12 fr-col-sm-7 fr-col-md-5 fr-col-lg-4 fr-col-xl-3 custom-button-center">
          <button class="fr-icon-settings-5-fill buttonChoix" (click)="redirectToAccueil()">
              <span>{{'FAVORIS.FAIRE_UN_CHOIX' | translate}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
