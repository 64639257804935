import { Component, HostListener, OnInit } from '@angular/core';
import { SharingdataService } from 'src/app/service/sharingdata.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  selecteAgent=false
  selecteEleve=false
  public lessThan_768!:boolean
  selectedProfil=""

  constructor(private sharingDataService:SharingdataService) { }

  ngOnInit(): void {
    this.getScreenSize()
  }

  clickTileAgent(){
    this.selecteAgent=true
    this.sharingDataService.changeProfil('AGENT')
    this.selectedProfil='AGENT'
    this.selecteEleve=false
  }

  clickTileEleve() {
    this.selecteAgent=false
    this.sharingDataService.changeProfil('ELEVE')
    this.selectedProfil='ELEVE'
    this.selecteEleve=true
  }



  
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.lessThan_768 = window.innerWidth < 768;
  }

}
