import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuile',
  templateUrl: './tuile.component.html',
  styleUrls: ['./tuile.component.css']
})
export class TuileComponent implements OnInit{


  @Input() type!:string;
  @Input() logo!:string;
  @Input() isTileSelected!:boolean;

  constructor() { }


  ngOnInit(): void {}

}
